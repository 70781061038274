.center-form {
    left: 0;
    right: 0;
    margin: auto;  
    inset-block: true; 
  }

  a {
      text-decoration: none;
      color:dimgrey
  }